input[type="checkbox"] {
  visibility: hidden;
  width: 0;
}

.customCheckbox {
  width: 20px;
  margin: 0 auto;
  position: relative;
}

.customCheckbox label {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  border-radius: 4px;

  background: #1b1924;
}

.customCheckbox label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 6px;
  left: 6px;
  border: 3px solid #666;
  border-top: none;
  border-right: none;

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.customCheckbox label:hover::after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.5;
}

.customCheckbox input[type="checkbox"]:checked + label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}
